@use '../../global' as *;
@use 'sass:math';

[class^="c-picIcon"],
[class*=" c-picIcon"] {
  display: inline-flex;
  width: 1em;
  height: 1em;
  &:before,
  &:after {
      display: inline-block;
      content: "";
      width: 1em;
      height: 1em;
  }
  &:after {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
  }
}

.c-picIconEye {
  &:before {
    background: img-url("icon-eye.svg") no-repeat center / contain;
  }
  &:after {
    background: img-url("icon-eye.svg") no-repeat center / contain;
  }
}