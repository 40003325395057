@use '../../global' as *;
@use 'sass:math';

.p-productMain {
  width: 100%;
  margin-top: -12px;
  @include mq-up(md) {
    margin-top: 0;
  }
  &__container {
    width: 100%;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    @include mq-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__vis {
    margin: 0 -17.5px;
    @include mq-up(md) {
      width: calc(1072px / 1366px * 100%);
      height: 100%;
      margin-right: 0;
      margin-left: -24px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__spec {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 9px;
    @include mq-up(md) {
      margin-top: 0;
      display: block;
      flex-direction: column;
      min-width: 240px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: calc(294px / 1366px * 100%);
      height: calc(100vh - 69px);
    }
  }
  &__specCatch {
    position: relative;
    margin-top: 54px;
    @include mq-up(md) {
      margin-top: 0;
      position: absolute;
      top: 72px;
    }
  }
  &__specCatchTtl {
    font-family: $font-family-base;
    @include lh-crop(33 / 24);
    line-height: calc(33 / 24);
    font-size: get_vwSP(24);
    letter-spacing: 0.03em;
    font-weight: $font-weight-regular;
    opacity: 0.99;
    @include mq-up(md) {
      @include lh-crop(37 / 26);
      line-height: calc(37 / 26);
      font-size: 2.6rem;
    }
  }
  &__specCatchDtl {
    font-family: $font-family-base;
    @include lh-crop(28 / 16);
    line-height: calc(28 / 16);
    font-weight: $font-weight-regular;
    font-size: 1.6rem;
    letter-spacing: 0.007em;
    margin-top: 24px;
    @include mq-up(md) {
      @include lh-crop(24 / 12);
      line-height: calc(24 / 12);
      font-size: 1.3rem;
      margin-top: 35px;
    }
  }
  &__specProductName {
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    font-size: 2.0rem;
    @include lh-crop(23 / 20);
    line-height: calc(23 / 20);
    opacity: 0.9;
    @include mq-up(md) {
      font-size: 1.7rem;
      @include lh-crop(23 / 17);
      line-height: calc(23 / 17);
    }
  }
  &__specProductTax {
    margin-top: 11px;
    @include mq-up(md) {
      margin-top: 10px;
    }
  }
  &__specProductShip {
    display: block;
    font-family: $font-family-en;
    font-weight: $font-weight-regular;
    font-size: 1.7rem;
    @include lh-crop(23 / 17);
    line-height: calc(23 / 17);
    margin-top: 15px;
    @include mq-up(md) {
      margin-top: 8px;
    }
  }
  &__specProduct {
    @include mq-up(md) {
      width: calc(100% / 5 - 16px);
      min-width: 240px;
    }
  }
  &__specProductMeta {
    @include mq-up(md) {
      mix-blend-mode: exclusion;
    }
  }
  &__specProductMeta,
  &__specProductBtn {
    @include mq-up(md) {
      position: fixed;
      z-index: 1;
      width: 100%;
      padding: 0;
      width: calc(20% - 26px);
      min-width: 240px;
      bottom: 0;
      right: auto;
      left: auto;
      transform: translate3d(0, 0, 0);
    }
  }
  &__specProductMeta {
    @include mq-up(md) {
      position: fixed;
      top: auto;
      bottom: 132px;
      color: $color-white;
      transition: all .07s cubic-bezier(.4,.25,.49,.66);
      padding: 0
    }
    &--loan {
      @include mq-up(md) {
        position: fixed;
        top: auto;
        bottom: calc(132px + 40px + 8px);
      }
    }
  }
  &__specProductBtn {
    top: auto;
    bottom: 23px;
    margin-top: 25px;
    @include mq-up(md) {
      bottom: 72px;
      margin-top: 0;
    }
    .c-button {
      width: 100%;
      height: 52px;
      font-size: 1.5rem;
      font-family: $font-family-base;
      font-weight: $font-weight-regular;
      color: $color-white;
      background-color: $color-black;
      letter-spacing: 0.03em;
      border-radius: 26px;
      padding-bottom: 3px;
      @include mq-up(md) {
        font-size: 1.4rem;
        height: 40px;
        border-radius: 20px;
        color: $color-black;
        background-color: $pjc-main-01;
        padding-bottom: 0;
      }
      @include hover {
        color: $color-white;
        background-color: $color-black;
      }
      &:after {
        display: none;
      }
    }
    .c-button--loan {
      background-color: transparent;
      color: $color-black;
    }
  }
}

.p-productSpec {
  margin-top: 49px;
  @include mq-up(md) {
    margin-top: 150px;
  }
  &__container {
    width: 100%;
  }
  &__inner {
    @include mq-up(md) {
      max-width: calc(812px / 1366px * 100%);
    }
  }
  &__dtl {
    font-size: 1.6rem;
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    color: $color-black;
    @include lh-crop(28 / 16);
    line-height: calc(28 / 16);
    letter-spacing: 0.007em;
    p + p {
      margin-top: 36px;
      @include mq-up(md) {
        margin-top: 24px;
      }
    }
    a {
      text-decoration: underline;
      transition: .2s;
      @include hover {
        opacity: 0.7;
      }
    }
    span {
      font-weight: $font-weight-medium;
    }
    + .p-productSpec__ttl {
      margin-top: 72px;
      @include mq-up(md) {
        margin-top: 54px;
      }
    }
  }
  &__list {
    margin-top: 18px;
    @include mq-up(md) {
      margin-top: 38px;
    }
  }
  &__ttl {
    font-size: 1.3rem;
    font-family: $font-family-en;
    font-weight: $font-weight-semiBold;
    @include lh-crop(15 / 13);
    line-height: calc(15 / 13);
    letter-spacing: 0.092em;
  }
  &__listItem {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #707070;
    padding-top: 16px;
    padding-bottom: 26px;
    @include mq-up(md) {
      align-items: center;
      flex-direction: row;
      padding-top: 26px;
      padding-bottom: 25px;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
  &__itemTerm {
    width: 100%;
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    font-size: 1.3rem;
    @include lh-crop(28 / 13);
    line-height: calc(28 / 13);
    letter-spacing: 0.007em;
    @include mq-up(md) {
      width: calc(121px / 780px * 100%);
    }
  }
  &__itemDesc {
    width: 100%;
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    font-size: 1.3rem;
    @include lh-crop(28 / 13);
    line-height: calc(28 / 13);
    letter-spacing: 0.007em;
    margin-top: 15px;
    @include mq-up(md) {
      margin-top: 0;
      width: calc(659px / 780px * 100%);
    }
  }
  &__add {
    display: block;
  }
  &__btn {
    width: 180px;
    height: 30px;
    border-radius: 20px;
    font-size: 1.3rem;
    letter-spacing: 0.03em;
    font-family: $font-family-en;
    font-weight: $font-weight-regular;
    margin-top: 46px;
    @include mq-up(md) {
      width: 253px;
      margin-top: 60px;
      padding-top: 1px;
    }
    @include hover {
      color: $color-white;
      background-color: $color-black;
    }
  }
  &__add {
    font-size: 1.6rem;
    @include lh-crop(23 / 16);
    line-height: calc(23 / 16);
    font-weight: $font-weight-light;
    font-family: $font-family-base;
    letter-spacing: 0.07em;
    margin-top: 52px;
    @include mq-up(md) {
      font-weight: $font-weight-regular;
    }
  }
}

.p-productPhoto {
  margin-top: 108px;
  position: relative;
  @include mq-up(md) {
    margin-top: 116px;
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }
  &__full {
    .p-productPhoto__vis {
      width: 100%;
    }
  }
  &__vis {
    width: 50%;
    height: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.p-productOther {
  margin-top: 153px;
  margin-bottom: 64px;
  @include mq-up(md) {
    margin-top: 150px;
    margin-bottom: 160px;
  }
  &__box {
    margin-top: 13px;
    @include mq-up(md) {
      margin-top: 47px;
    }
  }
  &__ttl {
    font-size: 1.3rem;
    font-family: $font-family-en;
    font-weight: $font-weight-semiBold;
    @include lh-crop(15 / 13);
    line-height: calc(15 / 13);
    letter-spacing: 0.092em;
  }
  &__specBtn {
    width: 180px;
    height: 30px;
    letter-spacing: 0.07em;
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    border-radius: 26px;
    margin-top: 32px;
    @include mq-up(md) {
      width: 199px;
      height: 40px;
      margin-top: 31px;
      font-size: 1.4rem;
      border-radius: 54px;
    }
  }
}

.p-productMain__specProductLoan {
  font-size: 1.4rem;
  font-weight: $font-weight-regular;
  @include lh-crop(28 / 14);
  line-height: calc(28 / 14);
  @include mq-up(md) {
    font-size: 1.2rem;
    @include lh-crop(24 / 12);
    line-height: calc(24 / 12);
  }
  span {
    display: inline-block;
    line-height: 100%;
    border-radius: 12px;
    padding: 3px 8px;
    color: $color-white;
    background-color: $color-black;
    font-size: 1.2rem;
    @include mq-up(md) {
      color: $color-black;
      background-color: $color-white;
    }
  }
  &Txt {
    margin-top: 5px;
  }
  + .p-productMain__specProductName {
    margin-top: 19px;
  }
}

.p-productMain__specProductFixBtn {
  left: 0;
  padding: 0 17.5px;
  position: fixed;
  right: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 1;
  bottom: 23px;
  opacity: 1;
  transition: all .3s cubic-bezier(.4,.25,.49,.66);
  @include mq-up(md) {
    display: none;
  }
  &.is-hide {
    opacity: 0;
    pointer-events: none;
    transition: all .3s cubic-bezier(.4,.25,.49,.66);
  }
  .c-button {
    width: 100%;
    height: 52px;
    font-size: 1.5rem;
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    color: $color-white;
    background-color: $color-black;
    letter-spacing: 0.03em;
    border-radius: 26px;
    padding-bottom: 3px;
    @include mq-up(md) {
      font-size: 1.4rem;
      height: 40px;
      border-radius: 20px;
      color: $color-black;
      background-color: $pjc-main-01;
      padding-bottom: 0;
    }
    @include hover {
      color: $color-white;
      background-color: $color-black;
    }
    &:after {
      display: none;
    }
  }
}