@use '../../global' as *;
@use 'sass:math';

.c-card {
  &--column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include mq-up(md) {
      flex-direction: row;
    }
  }
  &__vis {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    background-color: $color-white;
    &.p-productOther__vis {
      @include mq-up(md) {
        width: 50%;
      }
    }
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      object-fit: cover;
      transition: all .34s cubic-bezier(0.56, 0.17, 0.37, 0.75);
    }
  }
  &__spec {
    width: 100%;
    &.p-productOther__spec {
      margin-top: 24px;
      @include mq-up(md) {
        margin-top: 0;
        width: 50%;
        padding-left: calc(50px / 659px * 50%);
      }
    }
  }
  &__specTtl {
    font-family: $font-family-en;
    @include lh-crop(23 / 20);
    line-height: calc(23 / 20);
    font-size: 2.0rem;
    letter-spacing: 0.03em;
    font-weight: $font-weight-medium;
    opacity: 0.99;
    @include mq-up(md) {
      @include lh-crop(27 / 24);
      line-height: calc(27 / 24);
      font-size: 2.4rem;
    }
  }
  &__specDtl {
    font-family: $font-family-en;
    @include lh-crop(20 / 13);
    line-height: calc(20 / 13);
    font-weight: $font-weight-medium;
    opacity: 0.99;
    font-size: 1.3rem;
    margin-top: 18px;
    @include mq-up(md) {
      font-size: 1.4rem;
      @include lh-crop(20 / 14);
      line-height: calc(20 / 14);
    }
  }
  &__specTax {
    margin-top: 15px;
    @include mq-up(md) {
      margin-top: 16px;
    }
  }
  &__specAdd {
    display: block;
    font-family: $font-family-base;
    font-weight: $font-weight-light;
    font-size: 1.4rem;
    @include lh-crop(22 / 14);
    line-height: calc(22 / 14);
    opacity: 0.99;
    margin-top: 19px;
    @include mq-up(md) {
      display: none;
    }
  }
}

.js-fadeUp {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity .8s, transform .8s;
}

/* フェードイン(スクロールした後) */
.js-fadeUp.is-inview {
  opacity: 1;
  transform: translateY(0);
}