@use '../global' as *;

body {
  background-color: $pjc-main-01;
  transition: background-color .1s cubic-bezier(0.04, 0.49, 0.24, 0.66);
}

body {
  &.l-darkTheme {
    color: $color-white;
    background-color: $pjc-main-02;
  }
}

.p-front__menu {
  transition: background-color .1s cubic-bezier(0.04, 0.49, 0.24, 0.66);
}

body {
  &.l-darkTheme {
    .p-modalCart {
      color: $color-black;
    }
    .p-front__menu {
      color: $color-white;
      background-color: $pjc-main-02;
    }
    .p-front__menuItem:before {
      background-image: img-url("icon-dualArrow_white.svg");
      @include mq-up(md) {
        background-image: img-url("icon-dualArrow_white.svg");
      }
    }
    .p-header.is-color {
      background-color:$pjc-main-02;
      color: $color-white;
      &:before {
        background-image: img-url("logo-icon_white.svg");
      }
    }
    .p-header.p-header--front {
      background-color:initial;
    }
    .p-header.p-header--front.is-color {
      background-color:$pjc-main-02;
    }
    .p-header {
      background-color:$pjc-main-02;
    }
    .p-header__pcNavIcon svg path,
    .p-header.is-color .p-header__pcNavIcon svg path {
      fill: $color-white;
    }
    .p-front__menuItem {
      color: $color-white;
      @include mq-up(md) {
        color: $color-white;
      }
    }
    .p-productMain__specProductBtn {
      .c-button {
        color: $color-black;
        background-color: white;
        border: none;
        @include hover {
          color: $color-white;
        }
      }
    }
    .p-productSpec__btn,
    .p-frontProduct__specBtn,
    .c-formInputBtn,
    .p-mypage__change {
      border: none;
    }
    .p-shoppingAbout {
      background-color: $pjc-main-02;
      color: $color-white;
    }
    .p-shoppingAbout__contTtl--payTtl {
      &:before {
        background-image: img-url("icon-pay_white.svg");
      }
    }
    .p-shoppingAbout__contTtl--deliveryTtl {
      &:before {
        background-image: img-url("icon-delivery_white.svg");
      }
    }
    .p-shoppingAbout__contTtl--supportTtl {
      &:before {
        background-image: img-url("icon-support_white.svg");
      }
    }
    .p-productSpec__btn {
      @include hover {
        color: $color-white;
        background-color: $color-black;
      }
    }
    .p-contents__navListItem:before {
      background-image: img-url("../images/icon-dualArrow_white.svg");
    }
    .c-formInput {
      background-color: $pjc-main-01;
    }
    .p-mypage__group {
      border-bottom: 1px solid $color-white;
      &:last-child {
        border-bottom: 0;
      }
    }
    .p-mypage__logout {
      background-color: $pjc-main-01;
      color: $color-black;
      font-weight: $font-weight-regular;
      border: none;
      @include hover {
        color: $color-white;
        background-color: $color-black;
      }
    }
    .p-form__attention a {
      border-bottom: 1px solid $color-white;
    }
    .p-gnavBg {
      background-color: #F2F2F2;
    }
    .p-gnav {
      color: $color-black;
    }
    .p-header__hum.is-active span {
      background-color: $color-white;
    }
    .p-gnav__naviItemIcon svg path {
      fill: $color-black;
    }
    .p-header--front.is-color .p-header__spNavCart {
      color: $color-white;
    }
    .p-header__hum span {
      background-color: $color-white;
    }
    .p-header.is-color .p-header__hum span {
      background-color: $color-white;
    }
    .p-logo__icon--backImg {
      background-image: url(../images/logo-icon_white.svg);
    }
    .p-logo__body--backImg {
      background-image: url(../images/logo-body_white.svg);
    }
    .p-logo.p-gnav__logo .p-logo__icon.p-logo__icon--backImg {
      background-image: url(../images/logo-icon_black.svg);
    }
    .p-logo.p-gnav__logo .p-logo__body.p-logo__body--backImg {
      background-image: url(../images/logo-body_black.svg);
    }
    .p-productMain__specProductBtn .c-button {
      @include hover {
        color: $color-white;
        background-color: $color-black;
      }
    }
    .p-front__nextContIcon {
      background-image: url(../images/icon-next_white.svg);
    }
    .p-logo--posAb.is-change .p-logo__body--backImg {
      background-image: url(../images/logo-body_black.svg);
    }
    .c-button--arrowDiagonal {
      @include hover {
        &:after {
          background-image: img-url("icon-arrow-diagonal_white.svg");
        }
      }
    }
    .p-frontProduct__specBtn {
      @include hover {
        &:after {
          background-image: img-url("icon-arrow_white.svg");
        }
      }
    }
    .p-productSpec__dtl {
      color: $color-white;
    }
    .c-switchBtn input[type='checkbox'] + label:after {
      background: #1d1d1d;
    }
    .p-gnav__shareIcon svg path {
      fill: #1d1d1d;
    }
    .c-cart.p-header__spNavCart svg path {
      fill: $color-white;
    }
  }
}