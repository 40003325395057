@use '../../global' as *;
@use 'sass:math';

.p-contents {
  margin-top: calc(44px + 42px);
  margin-bottom: 142px;
  @include mq-up(md) {
    margin-top: calc(69px + 51px);
    margin-bottom: 160px;
  }
  &--article {
    margin-top: calc(44px + 39px);
    @include mq-up(md) {
      margin-top: calc(69px + 36px);
    }
  }
  &--productList {
    margin-top: calc(44px + 60px);
    @include mq-up(md) {
      margin-top: calc(69px + 42px);
    }
  }
  &--mypage {
    margin-bottom: 120px;
    @include mq-up(md) {
      margin-bottom: 218px;
    }
  }
  &__container {
    width: 100%;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    @include mq-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }
    &--column {
      display: block;
      .p-contents__main {
        width: 100%;
        @include mq-up(xl) {
          width: calc(780px / 1318px * 100%);
          margin: 0 auto;
        }
      }
    }
  }
  &__side {
    @include mq-up(md) {
      width: calc(512px / 1318px * 100%);
      height: 100%;
    }
  }
  &__main {
    @include mq-up(md) {
      width: calc(782px / 1318px * 100%);
      height: 100%;
    }
    &--productList {
      margin-top: 62px;
      @include mq-up(md) {
        margin-top: 0;
      }
    }
    &--article {
      margin-top: 71px;
      @include mq-up(md) {
        margin-top: 8px;
        margin-left: 40px;
      }
    }
  }
  &__navTtl {
    font-size: 4.7rem;
    @include lh-crop(53 / 47);
    line-height: calc(53 / 47);
    letter-spacing: 0.03em;
    font-family: $font-family-en;
    font-weight: $font-weight-regular;
    @include mq-up(md) {
      font-size: 6.2rem;
      @include lh-crop(60 / 62);
      line-height: calc(60 / 62);
    }
    &--smallType {
      font-size: 3.6rem;
      @include lh-crop(41 / 36);
      line-height: 140%;
      @include mq-up(md) {
        font-size: 3.6rem;
        @include lh-crop(41 / 36);
        line-height: 140%;
      }
    }
    &--centerType {
      font-size: 3.6rem;
      @include lh-crop(41 / 36);
      line-height: calc(41 / 36);
      text-align: center;
      @include mq-up(md) {
        font-size: 4.8rem;
        @include lh-crop(54 / 48);
        line-height: calc(54 / 48);
      }
    }
    &--centerSmallType {
      font-size: 3.6rem;
      text-align: center;
      @include mq-up(md) {
        font-size: 3.4rem;
      }
    }
    &--shoulder{
      display: block;
      font-family: $font-family-en;
      font-size: 1.4rem;
      margin-bottom: 24px;
    }
    span {
      display: block;
      margin-top: 20px;
      font-size: 1.6rem;
      @include mq-up(md) {
        margin-top: 32px;
        font-size: 2.0rem;
      }
    }
  }
  &__navList {
    display: flex;
    flex-wrap: wrap;
    margin-top: -14px;
    padding-top: 32px;
    @include mq-up(md) {
      display: inline-block;
      margin-top: 48px;
      padding-top: 0;
    }
    &.is-fixed {
      left: 0;
      position: fixed;
      top: 0;
    }
  }
  &__navListItem {
    font-size: 2.0rem;
    font-family: $font-family-en;
    line-height: calc(22 / 20);
    font-weight: $font-weight-exLight;
    opacity: 0.4;
    margin-right: 37px;
    margin-top: 13px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    transition: .2s cubic-bezier(0, 0.52, 0.12, 0.75);
    @include mq-up(md) {
      display: block;
      font-size: 1.8rem;
      line-height: calc(18 / 20);
      margin-right: 0;
      margin-top: 16px;
    }
    &:first-child {
      @include mq-up(md) {
        margin-top: 0;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &:before {
      content: "";
      display: block;
      width: 11px;
      height: 1em;
      background-image: img-url("icon-dualArrow_black.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      position: absolute;
      top: 2px;
      left: 2px;
      bottom: 0;
      opacity: 0;
      @include mq-up(md) {
        top: -2px;
        left: 0;
      }
    }
    &.is-active {
      opacity: 1;
      padding-left: 16px;
      pointer-events: none;
      @include mq-up(md) {
        padding-left: 17px;
      }
      &:before {
        opacity: 1;
      }
    }
    &:not(.is-active) {
      @include hover {
        opacity: 1;
      }
    }
  }
}
