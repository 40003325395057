@use '../../global' as *;
@use 'sass:math';

.p-shoppingAbout {
  width: 100%;
  background-color: $color-white;
  padding-top: 44px;
  padding-bottom: 100px;
  transition: all .1s cubic-bezier(0.04, 0.49, 0.24, 0.66);
  @include mq-up(lg) {
    padding-top: 60px;
    padding-bottom: 86px;
  }
  &--signup {
    display: none;
    @include mq-up(md) {
      display: block;
    }
  }
  &__container {
    width: 100%;
  }
  &__inner {
    width: 100%;
    padding: 0 6.5px;
    @include mq-up(lg) {
      max-width: 100%;
      margin: auto;
    }
  }
  &__ttl {
    font-family: $font-family-en;
    @include lh-crop(15 / 13);
    line-height: calc(15 / 13);
    font-size: 1.3rem;
    letter-spacing: 0.026em;
    font-weight: $font-weight-light;
    white-space: nowrap;
    span {
      padding-left: 34px;
      @include mq-up(lg) {
        padding-left: 28px;
      }
    }
  }
  &__contTtl {
    font-family: $font-family-base;
    line-height: calc(29 / 20);
    font-size: 2.0rem;
    letter-spacing: 0.03em;
    font-weight: $font-weight-light;
    display: flex;
    align-items: center;
    @include mq-up(lg) {
      font-size: 2.6rem;
      line-height: calc(37 / 26);
    }
    &:before {
      content: "";
      display: block;
      width: 27px;
      height: 27px;
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 7px;
      @include mq-up(lg) {
        margin-top: 4px;
        margin-right: 6px;
      }
    }
    &--payTtl {
      &:before {
        background-image: img-url("icon-pay_black.svg");
      }
    }
    &--deliveryTtl {
      &:before {
        background-image: img-url("icon-delivery_black.svg");
      }
    }
    &--supportTtl {
      &:before {
        background-image: img-url("icon-support_black.svg");
      }
    }
  }
  &__contDtl {
    font-family: $font-family-base;
    @include lh-crop(20 / 13);
    line-height: calc(20 / 13);
    font-size: 1.3rem;
    letter-spacing: 0.03em;
    font-weight: $font-weight-light;
    opacity: 0.99;
    margin-top: 20px;
    z-index: 1;
    @include mq-up(lg) {
      width: calc(206px / 244px * 100%);
      @include lh-crop(20 / 13);
      line-height: calc(20 / 13);
      font-size: 1.3rem;
      margin-top: 16px;
    }
  }
  &__inner {
    @include mq-up(lg) {
      display: flex;
      justify-content: space-between;
    }
  }
  &__ttl {
    @include mq-up(lg) {
      width: calc(100% / 5 - 16px);
    }
  }
  &__cont {
    width: 100%;
    margin-top: 48px;
    @include mq-up(lg) {
      margin-top: 0;
      width: calc(782px / 1318px * 100%);
    }
    @include mq-up(xl) {
      display: flex;
      justify-content: space-between;
    }
  }
  &__contItem {
    margin-top: 40px;
    @include mq-up(xl) {
      margin-top: 0;
      width: calc(244px / 782px * 100%);
    }
    &:first-child {
      margin-top: 0;
    }
  }
}