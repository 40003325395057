@use '../../global' as *;
@use 'sass:math';

.p-productList {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: -82px;
  @include mq-up(md) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -96px;
  }
  &__item {
    display: block;
    padding-bottom: 82px;
    @include mq-up(md) {
      width: calc(379px / 782px * 100%);
      height: 100%;
      padding-bottom: 96px;
    }
    .c-card__specTtl {
      font-weight: $font-weight-regular;
      font-size: 2.4rem;
      @include lh-crop(27 / 24);
      line-height: calc(27 / 24);
      @include mq-up(md) {
        font-weight: $font-weight-medium;
        font-size: 2.0rem;
      }
      @include mq-up(lg) {
        font-size: 2.4rem;
      }
    }
    .c-card__spec {
      margin-top: 21px;
      @include mq-up(md) {
        margin-top: 13px;
      }
    }
    .c-card__specDtl {
      font-size: 1.6rem;
      @include lh-crop(22 / 16);
      line-height: calc(22 / 16);
      @include mq-up(md) {
        font-size: 1.3rem;
        @include lh-crop(20 / 14);
        line-height: calc(20 / 14);
      }
      @include mq-up(lg) {
        font-size: 1.4rem;
      }
    }
    .c-card__specDtl {
      margin-top: 10px;
    }
    &.is-hide {
      display: none;
      opacity: 0;
    }
    a {
      @include hover{
        img {
          transform: scale(1.05);
        }
      }
    }
  }
}