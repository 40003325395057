@use '../../global' as *;

.u-forSP {
  @include mq-up(md) {
    display: none;
  }
}

.u-forPC {
  display: none;
  @include mq-up(md) {
    display: block;
  }
}

.u-forLongTxt {
  display: none;
  @include mq-up(md) {
    display: block;
  }
  @include mq-up(lg) {
    display: none;
  }
}
