@use '../../global' as *;
@use 'sass:math';

.p-header {
  background-color: #F2F2F2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all .1s cubic-bezier(0.04, 0.49, 0.24, 0.66);
  &.is-hide {
    .p-header__spNav {
      display: block;
    }
  }
  &--front {
    background-color: transparent;
    .p-header__logo {
      opacity: 0;
    }
    .p-logo__icon.p-logo__icon--backImg {
      background-image: url(../images/logo-icon_white.svg);
    }
    .p-logo__body.p-logo__body--backImg {
      background-image: url(../images/logo-body_white.svg);
    }
    .p-header__hum span {
      background-color: $color-white;
    }
    .p-header__spNavCart {
      color: $color-white;
      @include mq-up(md) {
        color: $color-black;
      }
      svg path {
        fill: $color-white;
      }
    }
    &.is-color {
      .p-header__spNavCart {
        color: $color-black;
      }
      svg path {
        fill: $color-black;
      }
    }
    .p-logo__body--backImg {
      display: block;
    }
  }
  &.is-color {
    background-color: #F2F2F2;
    color: $color-black;
    &:before {
      content: "";
      display: block;
      background-image: img-url("logo-icon_black.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 22.08px;
      height: 18.61px;
      position: absolute;
      top: 12px;
      left: 19px;
      transition: .2s ease;
      @include mq-up(md) {
        width: 29.09px;
        height: 24.52px;
        top: 20px;
        left: 24px;
      }
    }
    .p-header__pcNavIcon svg path {
      fill: $color-black;
    }
  }
  &__logo {
    position: absolute;
    top: 5px;
    left: 19px;
    opacity: 1;
    @include mq-up(md) {
      top: 20px;
      left: 24px;
    }
    h1 {
      font-size: 0;
    }
  }
  &__inner {
    width: 100%;
    height: 100%;
  }
  &__spNav {
    display: flex;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 20px;
    @include mq-up(md) {
      display: none;
    }
    svg path {
      fill: $color-black;
    }
  }
  &__pcNav {
    display: none;
    @include mq-up(md) {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  &__hum {
    position: relative;
    cursor: pointer;
    mix-blend-mode: exclusion;
    width: 26px;
    height: 7px;
    z-index: 999;
    span {
      display: inline-block;
      transition: transform .3s cubic-bezier(0.33, 1, 0.68, 1);
      position: absolute;
      width: 26px;
      height: 1px;
      background-color: $color-white;
      left: 0;
      &:nth-of-type(1) {
        top: 1px;
      }
      &:nth-of-type(2) {
        top: 5px;
      }
    }
    &.is-active {
      span {
        background-color: $color-white;
        &:nth-of-type(1) {
          transform: translateY(4px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          transform: translateY(0) rotate(45deg);
        }
      }
    }
  }
  &__spNavCart {
    position: absolute;
    top: -4px;
    right: 42px;
    .c-cart__txt {
      margin-left: 4px;
    }
  }
  &__pcNavWrap {
    display: flex;
    width: 289px;
    padding-left: 38px;
    padding-bottom: 7px;
  }
  &__pcNavInner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__pcNavItem {
    + .p-header__pcNavItem {
      margin-left: 36px;
    }
  }
  &__pcNavTxt {
    font-size: 1.5rem;
    font-weight: $font-weight-medium;
    font-family: $font-family-en;
    line-height: calc(26 / 15);
    @include lh-crop(26 / 15);
    letter-spacing: 0.017em;
    .c-cart__num span {
      padding: 0;
    }
  }
  &__pcNavSwitch {
    right: 28px;
    top: -1px;
  }
  &__pcNavIcon {
    &--cartIcon {
      margin-left: -5px;
    }
    svg path {
      fill: $color-black;
    }
  }
}

.c-cart {
  display: flex;
  white-space: nowrap;
  &__txt,
  &__num {
    font-size: 1.3rem;
    font-weight: $font-weight-medium;
    font-family: $font-family-en;
    line-height: calc(20 / 13);
    @include lh-crop(20 / 13);
    @include mq-up(md) {
      font-size: 1.5rem;
      line-height: calc(26 / 15);
      @include lh-crop(26 / 15);
      letter-spacing: 0.017em;
    }
  }
  &__num {
    padding-left: 2px;
    span {
      padding: 0 2px;
    }
  }
}

.p-logo {
  display: flex;
  &__icon {
    img {
      width: 22.08px;
      @include mq-up(md) {
        width: 29.09px;
      }
    }
    &--overlay {
      mix-blend-mode: exclusion;
      background-image: none;
    }
    &--backImg {
      background-image: url(../images/logo-icon_black.svg);
      height: 18.61px;
      width: 22.08px;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      top: 5px;
      left: 0;
      @include mq-up(md) {
        width: 29.09px;
        height: 24.52px;
      }
    }
  }
  &__body {
    position: relative;
    top: 6px;
    left: 5px;
    img {
      width: 138.72px;
      @include mq-up(md) {
        width: 168.32px;
      }
    }
    &--backImg {
      background-image: url(../images/logo-body_black.svg);
      height: 14.87px;
      width: 138.72px;
      background-repeat: no-repeat;
      background-size: contain;
      top: 13px;
      left: 5px;
      display: none;
      @include mq-up(md) {
        width: 168.32px;
        height: 18.04px;
      }
    }
  }
  &--posAb {
    height: 14.87px;
    width: 138.72px;
    left: 41px;
    position: absolute;
    top: 5px;
    z-index: 10;
    @include mq-up(md) {
      width: 168.32px;
      height: 18.04px;
      top: 23px;
      left: 54px;
    }
    .p-logo__body--backImg {
      display: block;
    }
    &.is-change {
      .p-logo__body--backImg {
        background-image: url(../images/logo-body_white.svg);
      }
    }
  }
}

.p-logo.p-gnav__logo {
  .p-logo__icon.p-logo__icon--backImg {
    background-image: url(../images/logo-icon_white.svg);
  }
  .p-logo__body.p-logo__body--backImg {
    background-image: url(../images/logo-body_white.svg);
  }
}

.p-gnav {
  bottom: 0;
  height: 100vh;
  left: 0;
  min-width: 320px;
  opacity: 0;
  overflow-x: auto;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 100%;
  color: $color-white;
  transition: all .2s cubic-bezier(0.08, 0.55, 0.41, 1.04);
  z-index: 1;
  &.is-open {
    opacity: 1;
    visibility: visible;
  }
  &__logo {
    position: absolute;
    top: 5px;
    left: 19px;
  }
  &__inner {
    margin-top: 118px;
    padding-left: 20px;
  }
  &__naviList {
    animation-name:gnaviAnime;
    animation-duration:1s;
    animation-delay:.1s;
    animation-fill-mode:forwards;
    opacity:0;
  }
  &Bg {
    position: fixed;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: $pjc-main-02;
    transform: scale(0);
    right:-50px;
    top:-50px;
    transition: all .3s ease;
    &.is-open {
      transform: scale(50);
    }
  }
  &__naviItemWrap {
    display: flex;
  }
  &__naviListSub {
    margin-top: 103px;
    // margin-left: 34px;
  }
  &__naviItemTxt {
    font-size: 2.8rem;
    font-weight: $font-weight-regular;
    letter-spacing: 0.05em;
    font-family: $font-family-en;
    line-height: calc(32 / 28);
    @include lh-crop(32 / 28);
    mix-blend-mode: exclusion;
    margin-left: 12px;
    .c-cart__txt,
    .c-cart__num {
      font-size: 2.8rem;
      font-weight: $font-weight-regular;
      letter-spacing: 0.05em;
      font-family: $font-family-en;
      line-height: calc(32 / 28);
      @include lh-crop(32 / 28);
    }
  }
  &__naviListSubItem {
    font-size: 1.5rem;
    font-weight: $font-weight-regular;
    letter-spacing: 0.010em;
    font-family: $font-family-en;
    line-height: calc(18 / 16);
    @include lh-crop(18 / 16);
    mix-blend-mode: exclusion;
    + .p-gnav__naviListSubItem {
      margin-top: 21px;
    }
  }
  &__naviItem {
    + .p-gnav__naviItem {
      margin-top: 35px;
    }
    + .p-gnav__naviItem + .p-gnav__naviItem {
      .p-gnav__naviItemTxt {
        margin-left: 15px;
        .c-cart__num span {
          padding: 0;
        }
      }
    }
  }
  &__foot {
    margin-top: 68px;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__shareWrap {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: -4px;
  }
  &__shareIcon {
    + .p-gnav__shareIcon {
      margin-left: 21px;
    }
  }
}

@keyframes gnaviAnime{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}