@use '../global' as *;

.l-header {
  width: 100%;
  height: 44px;
  @include mq-up(md) {
    height: 69px;
  }
  &.p-header--front + .l-main {
    margin-top: 0;
    @include mq-up(md) {
      margin-top: 0;
    }
  }
  + .p-logo--posAb + .l-main {
    margin-top: 44px;
    @include mq-up(md) {
      margin-top: 69px;
    }
  }
}

.l-container {
  width: 100%;
  max-width: 100%;
  padding-right: 17.5px;
  padding-left: 17.5px;
  @include mq-up(md) {
    // max-width: 1366px;
    // margin: 0 auto;
    padding-right: 24px;
    padding-left: 24px;
  }
}

.l-footer {
  width: 100%;
  height: 100%;
  min-height: 420px;
  background-color: $pjc-main-02;
  @include mq-up(md) {
    min-height: 337px;
  }
}