@use '../../global' as *;
@use 'sass:math';

.p-form {
  margin-top: 123px;
  @include mq-up(md) {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 82px;
  }
  &__headRead{
    text-align: center;
    margin-bottom: 40px;
  }
  &__headBox{
    margin-bottom: 20px;
  }
  &__title{}
  &__read{
    margin-top: 6px;
    @include mq-up(md) {
      margin-top: 6px;
    }
  }
  &__link{
    margin-top: 8px;
    li{
      margin-bottom: 4px;
        a {
          border-bottom: 1px solid #313131;
        }
    }
  }
  &__required {
    display: block;
    font-size: 1.2rem;
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    @include lh-crop(28 / 12);
    line-height: calc(28 / 12);
  }
  &__wrap {
    margin-top: 16px;
  }
  &__input {
    position: relative;
    + .p-form__input {
      margin-top: 20px;
    }
    + .p-form--selectFlex {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &--short {
      width: 100%;
      @include mq-up(md) {
      width: 36%;
    }
  }
  }
  &__attention {
    display: block;
    font-size: 1.6rem;
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    @include lh-crop(28 / 16);
    line-height: calc(28 / 16);
    margin-top: 35px;
    @include mq-up(md) {
      margin-top: 28px;
    }
    a {
      border-bottom: 1px solid #313131;
    }
  }
  &__submit {
    margin-top: 52px;
    @include mq-up(md) {
      margin-top: 44px;
    }
  }
  &--submitFlex{
    display: flex;
  }

  &--selectFlex{
    display: block;
    @include mq-up(md) {
      display: flex;
      align-items: center;
    }
  }
  &--selectFlexInner{
    width: 100%;
    display: flex;
    align-items: center;
    @include mq-up(md) {
      width: 50%;
    }
    &:nth-child(2){
      margin-top: 20px;
        @include mq-up(md) {
        margin-top: 0px;
      }
    }
    .p-form__input{
      width: 50%;
      @include mq-up(md) {
        width: 75%;
      }
    }
    span{
      padding-left: 7px;
      display: inline-block;
    }
  }

}
