@use '../../global' as *;
@use 'sass:math';

.c-loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: $pjc-main-01;
  z-index: 555;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__img {
    padding: 0 64px;
    transition: all .6s ease;
    clip-path: inset(0 100% 0 0);
    @include mq(md) {
      padding: 0;
    }
    &.is-show {
      clip-path: inset(0);
    }
  }
}

_:lang(x)+_:-webkit-full-screen-document, .c-loader__img {
  position: relative;
  bottom: 4em;
}