@use '../../global' as *;
@use 'sass:math';

.c-formInput {
  width: 100%;
  min-height: 60px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  font-size: 1.6rem;
  background-color: rgba($color-white, 0.21);
  border: 1px solid #727272;
  position: relative;
  padding-top: 1px;
  padding-left: 17px;
  color: $color-black;
  &::placeholder {
    // font-size: 1.4rem;
    color: #727272;
    font-weight: $font-weight-medium;
  }
  &:focus::placeholder {
    color: transparent;
  }
}
.c-formTextArea{
  width: 100%;
  min-height: 200px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  font-size: 1.6rem;
  background-color: rgba($color-white, 0.21);
  border: 1px solid #727272;
  position: relative;
  padding: 16px;
  color: $color-black;
  resize: vertical;
  &::placeholder {
    // font-size: 1.4rem;
    color: #111111;
    font-weight: $font-weight-medium;
  }
  &:focus::placeholder {
    color: transparent;
  }
}
.c-formSelect {
  width: 100%;
  min-height: 60px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  font-size: 1.6rem;
  background-color: rgba($color-white, 0.21);
  border: 1px solid #727272;
  position: relative;
  padding-top: 1px;
  padding-left: 17px;
  padding-right: 17px;
  color: $color-black;
  select{
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    appearance: none;
    min-height: 60px;
  }
  &::placeholder {
    // font-size: 1.4rem;
    color: #111111;
    font-weight: $font-weight-medium;
  }
  &:focus::placeholder {
    color: transparent;
  }
  &::before {
  border-top: solid 2px #000;
  border-right: solid 2px #000;
  content: "";
  position: absolute;
  right: 15px;
  top: 23px;
  height: 12px;
  width: 12px;
  transform: rotate(135deg);
}
}

.c-pass {
  cursor: pointer;
  display: none;
  position: absolute;
  right: 18px;
  top: 10px;
  font-size: 2.7rem;
  opacity: 0;
  transition: all .2s ease;
  @include mq-up(md) {
    display: inline-block;
    opacity: 1;
  }
  &.is-opacity {
    opacity: 0.5;
  }
}

.c-formInputBtn {
  width: 100%;
  height: 52px;
  border-radius: 30px;
  font-size: 1.5rem;
  font-weight: $font-weight-regular;
  font-family: $font-family-base;
  letter-spacing: 0.03em;
  @include mq-up(md) {
    height: 60px;
    font-size: 1.8rem;
  }
  &--back{
    width: 120px;
    flex-shrink: 0;
    margin-right: 8px;
    @include mq-up(md) {
      margin-right: 12px;
    }
  }
  &--short{
    width: 200px;
    flex-shrink: 0;
    @include mq-up(md) {
    }
  }
    &--card{
    width: 220px;
    flex-shrink: 0;
    @include mq-up(md) {
      width: 240px;
    }
  }
  &--middle{
    height: 48px;
    @include mq-up(md) {
      height: 48px;
    }
  }
  &--small{
    @include mq-up(md) {
      height: 26px;
      font-size: 1.2rem;
    }
    height: 26px;
    font-size: 1.2rem;
  }
  &--cross{
    position: relative;
    &::after{
      position: absolute;
      content: '';
      width:14px;
      height: 1px;
      background: $color-black;
      display: block;
      top:50%;
      left: 10px;
      transform: rotate(45deg);
      z-index: 2;
    }
    &::before{
      position: absolute;
      content: '';
      width:14px;
      height: 1px;
      background: $color-black;
      display: block;
      top:50%;
      left: 10px;
      transform: rotate(-45deg);
      z-index: 2;
    }
  }
}

input[type=radio] {
  display: none;
}

.c-dtlRadiobox {
  margin-top: 12px;
  position: relative;
  @include mq-up(md) {
    margin-top: 24px;
  }
  @include mq-up(lg) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
  }
  &__ttl {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 27px;
    font-size: 1.4rem;
    font-family: $font-family-proxima;
    font-weight: $font-weight-light;
    letter-spacing: .052em;
    line-height: 140%;
    // margin-top: 24px;
    padding-top: 2px;
    @include mq-up(md) {
      font-size: 1.8rem;
      padding-left: 34px;
      padding-right: 44px;
    }
    + .c-dtlRadiobox__ttl {
      margin-top: 12px;
      @include mq-up(md) {
        margin-top: 16px;
      }
      @include mq-up(lg) {
        margin-top: 0;
      }
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.c-customRadio {
  width: 19px;
  height: 19px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #EAEAEA;
  left: 0;
  position: absolute;
  top: 0;
  margin-top: 1px;
  @include mq-up(md) {
    margin-top: 2px;
    width: 23px;
    height: 23px;
  }
  &:after {
    content: "";
    position: absolute;
    background-color: $color-white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: none;
    left: 1px;
    top: 1px;
    @include mq-up(md) {
      width: 19px;
      height: 19px;
    }
  }
}

.c-radioInput:checked {
  & ~ .c-customRadio:after {
    display: block;
    opacity: 1;
  }
  // + .c-customRadio {
  //   border: 1px solid #747474;
  // }
}

.c-textInput {
  width: 100%;
  min-height: 160px;
  background-color: #2F2F2F;
  border: 1px solid #414141;
  border-radius: 10px;
  resize: none;
  // cursor: inherit;
  padding: 12px 16px;
  font-size: 1.6rem;
  @include mq-up(md) {
    min-height: 220px;
  }
  &::-webkit-resizer {
    display: none;
  }
  &--innerBox {
    margin-top: 40px;
  }
  &.is-hide {
    display: none;
  }
}

input[type=checkbox] {
  display: none;
}

.c-dtlCheckbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__ttl {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 29px;
    font-size: 1.4rem;
    line-height: 140%;
    font-family: $font-family-proxima;
    font-weight: $font-weight-light;
    margin-right: 24px;
    @include mq-up(md) {
      padding-left: 38px;
      font-size: 1.8rem;
    }
  }
}

.c-customCheck {
  width: 19px;
  height: 19px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid $color-white;
  left: 0;
  position: absolute;
  top: -1px;
  @include mq-up(md) {
    width: 23px;
    height: 23px;
  }
  &:after {
    content: "";
    width: 8px;
    height: 12px;
    left: 5px;
    margin-top: -8px;
    border-bottom: 2px solid #f2f2f2;
    border-right: 2px solid #f2f2f2;
    display: none;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    @include mq-up(md) {
      width: 9px;
      height: 13px;
      left: 6px;
      margin-top: -9px;
    }
  }
}

input:checked ~ .c-customCheck:after {
  display: block;
  opacity: 1;
}