@use '../../global' as *;
@use 'sass:math';

.p-mypage {
  margin-top: 120px;
  @include mq-up(md) {
    margin-top: 82px;
  }
  &__group {
    padding-top: 32px;
    padding-bottom: 35px;
    border-bottom: 1px solid $color-black;
    @include mq-up(md) {
      padding-bottom: 36px;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    &--history {
      padding-bottom: 39px;
      @include mq-up(md) {
        padding-bottom: 46px;
      }
    }
    &--historyLine{
        padding-bottom: 0px;
        border-bottom: none;
      @include mq-up(md) {
        padding-bottom: 0px;
      }
    }
    &--payment {
      @include mq-up(md) {
        padding-bottom: 60px;
      }
    }
    &--adress {
      padding-bottom: 40px;
      @include mq-up(md) {
        padding-bottom: 36px;
      }
      .p-mypage__adress {
        margin-top: 11px;
      }
      .p-mypage__name {
        margin-top: 12px;
        @include mq-up(md) {
          margin-top: 16px;
        }
      }
    }
  }
  &__ttl {
    font-size: 1.4rem;
    @include lh-crop(28 / 14);
    line-height: calc(28 / 14);
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
  }
  &__name {
    margin-top: 11px;
    @include mq-up(md) {
      margin-top: 16px;
    }
  }
  &__adress {
    span {
      padding-right: 8px;
      @include mq-up(md) {
        padding-right: 16px;
      }
    }
  }
  &__inner--paymentWithButton{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
     @include mq-up(md) {
       flex-wrap: nowrap;
      }
    .p-mypage__adressButtons{
      margin-left: auto;
      margin-top: 30px;
      width: 100%;
      justify-content: flex-end;
      @include mq-up(md) {
        margin-top: 21px;
        width: auto;
      }
    }
  }

  &__group--adressWithButton{
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    @include mq-up(md) {
      flex-wrap: nowrap;
    }
  }
  &__adressButtons{
    margin-left: auto;
    display: flex;
    margin-top: 30px;
    @include mq-up(md) {
      margin-top: 0;
      margin-left: 0;
      display: block;

    }
    .c-button{
      width: 87px;
      &:nth-child(1){
        margin-right: 4px;
        @include mq-up(md) {
           margin-right: 0px;
          margin-bottom: 4px;
        }
      }
    }
  }
  &__name,
  &__creditTtl,
  &__adress {
    font-size: 1.8rem;
    @include lh-crop(28 / 18);
    line-height: calc(28 / 18);
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
  }
  &__creditTtl {
    @include mq-up(md) {
      font-size: 1.6rem;
      @include lh-crop(28 / 16);
      line-height: calc(28 / 16);
    }
  }
  &__change {
    height: 42px;
    border-radius: 23px;
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    font-size: 1.4rem;
    padding-top: 2px;
    margin-top: 32px;
    @include mq-up(md) {
      max-width: 391px;
      height: 48px;
      font-size: 1.8rem;
      // padding-top: 4px;
      margin-top: 24px;
    }
    &--payment {
      @include mq-up(md) {
        margin-top: 35px;
        padding-right: 17px;
      }
    }
    @include hover {
      background-color: $color-black;
      color: $color-white;
    }
  }
  &__historyDay,
  &__historyItemName,
  &__historyItemDtl {
    font-size: 1.6rem;
    @include lh-crop(28 / 16);
    line-height: calc(28 / 16);
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    display: block;
  }
  &__credit {
    margin-top: 11px;
    @include mq-up(md) {
      margin-top: 21px;
      display: flex;
    }
    + .p-mypage__history {
      margin-top: 21px;
    }
    &--scription {
      display: block;
      @include mq-up(md) {
        display: none;
      }
    }
  }
  &__historyItemPay {
    margin-top: 8px;
    @include mq-up(md) {
      padding-left: 32px;
      margin-top: 0;
    }
  }
  &__creditTtl {
    span {
      padding-left: 3px;
    }
  }
  &__history {
    display: flex;
    align-items: baseline;
    margin-top: 17px;
    @include mq-up(md) {
      margin-top: 21px;
    }
    + .p-mypage__history {
      margin-top: 35px;
      @include mq-up(md) {
        margin-top: 21px;
      }
    }

    &--line{
      border-bottom: solid 1px $color-black;
      padding: 10px 0 35px;
      .p-mypage__historyDay{
        font-size: 1.3rem;
        width: 40%;
        @include mq-up(md) {
          width: 17%;
        }
      }
      .p-mypage__historyItem{
        width: 100%;
        @include mq-up(md) {
          width: 83%;
        }
      }
      .p-mypage__historyItemName{
        font-size: 1.4rem;
        span{
          font-size: 1.6rem;
          display: inline-block;
          padding: 0;
          @include mq-up(md) {
            padding: 0;
          }
        }
        @include mq-up(md) {
          width: 52.89%;
        }
      }
      .p-mypage__historyItemNameTxt {
        display: inline-block;
        margin-right: 16px;
      }
      .p-mypage__historyItemMeta{
        width: 100%;
      }
      // .p-mypage__historyItemDtl{
      //   font-size: 1.6rem;
      //   @include mq-up(md) {
      //     width: 40%;
      //   }
      // }
    }
  }


  &__historyItem {
    @include mq-up(md) {
      display: flex;
      align-items: center;
    }
  }
  &__historyDay {
    padding-right: 16px;
    display: block;
  }
  &__historyItemMeta {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    @include mq-up(md) {
      align-items: center;
      flex-direction: row;
      margin-top: 0;
      padding-left: 24px;
    }
  }
  &__historyItemName {
    span {
      padding-left: 4px;
    }
  }
  &__creditInfo {
    font-size: 1.6rem;
    @include lh-crop(28 / 16);
    line-height: calc(28 / 16);
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    margin-top: 16px;
    @include mq-up(md) {
      margin-top: 0;
      font-size: 1.4rem;
      @include lh-crop(28 / 14);
      line-height: calc(28 / 14);
      padding-left: 87px;
      padding-top: 1px;
    }
  }
  &__creditPeriod {
    display: block;
    margin-top: -2px;
    @include mq-up(md) {
      margin-top: -6px;
    }
  }
  &__creditNum {
    margin-top: -5px;
    @include mq-up(md) {
      margin-top: -10px;
    }
  }
  &__logout {
    width: 199px;
    height: 40px;
    border-radius: 20px;
    font-family: $font-family-roboto;
    font-weight: $font-weight-light;
    font-size: 1.4rem;
    letter-spacing: 0.03em;
    margin-top: 55px;
    padding-bottom: 1px;
    padding-left: 3px;
    @include mq-up(md) {
      margin-top: 103px;
      padding-left: 0;
      padding-bottom: 2px;
    }
    @include hover {
      font-weight: $font-weight-regular;
    }
  }

  &__submit {
    margin-top: 52px;
    @include mq-up(md) {
      margin-top: 44px;
    }
  }
  &--submitFlex{
    display: flex;
  }
}
