@use '../../global' as *;
@use 'sass:math';

.p-modal {
  position: relative;
  transition: .2s cubic-bezier(.82,.02,.48,1.13);
  z-index: 2000;
  &.is-hide {
    opacity: 0;
    pointer-events: none;
    visibility: visible;
  }
  &__cont {
    width: calc(1230px / 1366px * 100%);
    height: calc(100% - 80px);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    color: $color-white;
  }
  &__inner {
    background-color: #343434;
    width: 100%;
    height: 100%;
    padding: 48px 16px 80px;
    overflow-y: scroll;
    @include mq-up(md) {
      padding: 82px 76.5px 125px;
    }
  }
  &__bg {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-black;
    opacity: 0.52;
    z-index: -1;
  }
  &__ttl {
    font-size: 2.8rem;
    font-family: $font-family-proxima;
    line-height: 100%;
    font-weight: $font-weight-light;
    letter-spacing: .062em;
    @include mq-up(md) {
      font-size: 4.0rem;
    }
  }
  &__dtl {
    font-size: 1.6rem;
    font-family: $font-family-proxima;
    font-weight: $font-weight-thin;
    line-height: calc(32 / 18);
    letter-spacing: .03em;
    margin-top: 51px;
    @include mq-up(md) {
      font-size: 1.8rem;
    }
  }
  &__colse {
    width: 21px;
    height: 21px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    @include mq-up(md) {
      right: 48px;
      top: 52px;
    }
    span {
      background-color: $color-white;
      position: absolute;
      left: -3px;
      width: 28px;
      height: 1px;
      display: inline-block;
      &:nth-child(1) {
        top: 0;
        transform: translateY(10px) rotate(-45deg);
      }
      &:nth-child(2) {
        bottom: 0;
        transform: translateY(-10px) rotate(45deg);
      }
    }
  }
}

.p-modalForm {
  width: 100%;
  margin-top: 48px;
  // padding: 0 8px;
  @include mq-up(md) {
    margin-top: 97px;
  }
  &__group {
    width: 100%;
    border-bottom: 1px solid #707070;
    padding-bottom: 36px;
    @include mq-up(lg) {
      display: flex;
      padding-bottom: 46px;
    }
    + .p-modalForm__group {
      margin-top: 32px;
      @include mq-up(md) {
        margin-top: 44px;
      }
    }
    &--list {
      @include mq-up(md) {
        align-items: flex-start;
      }
    }
    &--visGroup {
      @include mq-up(lg) {
        display: block;
      }
    }
    &Body {
      @include mq-up(lg) {
        display: flex;
      }
    }
  }
  &__groupVisSp {
    margin-top: 23px;
    margin-bottom: 30px;
    @include mq-up(lg) {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      max-width: 307px;
    }
  }
  &__groupVisPc {
    display: none;
    @include mq-up(lg) {
      display: block;
      margin-top: 16px;
      padding-left: 44px;
    }
    img {
      @include mq-up(lg) {
        width: 100%;
        max-width: 391px;
        height: 100%;
      }
    }
  }
  &__ttl {
    width: 100%;
    font-size: 1.4rem;
    font-family: $font-family-proxima;
    font-weight: $font-weight-light;
    letter-spacing: .052em;
    line-height: calc(28 / 16);
    @include lh-crop(28 / 16);
    @include mq-up(md) {
      font-size: 1.6rem;
    }
    @include mq-up(lg) {
      display: flex;
      padding-right: 48px;
      margin-top: 4px;
      width: calc(735px / 1077px * 100%);
    }
  }
  &__box {
    width: 100%;
    @include mq-up(lg) {
      width: calc(390px / 1077px * 100%);
    }
    &--multiple {
      // margin-top: -24px;
      // @include mq-up(md) {
      //   margin-top: -24px;
      // }
      .c-dtlRadiobox__ttl {
        width: 100%;
        margin-top: 12px;
        min-width: inherit;
        margin-right: 0;
        padding-right: 0;
        @include mq-up(lg) {
          margin-top: 24px;
        }
        &:first-child {
          margin-top: 0;
        }
      }
      .c-dtlRadiobox__ttlInfo {
        display: block;
        margin-top: 8px;
        color: #FFF502;
        line-height: 100%;
      }
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 56px;
    font-size: 1.3rem;
    font-family: $font-family-proxima;
    font-weight: $font-weight-regular;
    color: $color-white;
    line-height: 100%;
    @include mq-up(md) {
      margin-top: 64px;
    }
  }
  &__btnSubmit {
    width: 100%;
    height: 52px;
    background-color: #111111;
    border-radius: 26px;
    letter-spacing: 0.114em;
    transition: all .07s cubic-bezier(0.4, 0.25, 0.49, 0.66);
    @include mq-up(md) {
      width: 566px;
    }
    @include hover {
      background-color: #f2f2f2;
      border: 1px solid #000;
      color: #000;
    }
    &:disabled {
      background-color: #adadad;
      color: $color-white;
    }
  }
  &__required {
    font-size: 1.1rem;
    font-weight: $font-weight-light;
    color: $color-white;
    margin-right: 16px;
    white-space: nowrap;
    margin-bottom: 12px;
    @include mq-up(md) {
      margin-top: 4px;
    }
  }
  &__privacyHead {
    font-size: 1.6rem;
    font-family: $font-family-proxima;
    font-weight: $font-weight-light;
    margin-bottom: 14px;
    @include mq-up(md) {
      margin-bottom: 32px;
    }
  }
  &__privacy {
    margin-top: 48px;
    @include mq-up(lg) {
      margin-top: 65px;
    }
  }
  &__privacyAttention {
    font-size: 1.5rem;
    line-height: calc(26 / 15);
    color: #FFEB00;
    letter-spacing: 0.052em;
    font-weight: $font-weight-light;
    text-align: center;
    font-family: $font-family-proxima;
    @include mq-up(lg) {
      font-size: 1.8rem;
      line-height: calc(26 / 18);
    }
  }
  &__privacyBody {
    width: 100%;
    height: 463px;
    min-height: 463px;
    border: 1px solid #B5B5B5;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 26px;
  }
  &__privacyBodyInner {
    padding: 32px 24px;
    overflow-y: scroll;
    height: 100%;
    @include mq-up(lg) {
      padding: 50px;
    }
  }
  &__privacyBodyTxt {
    font-size: 1.3rem;
    font-weight: $font-weight-light;
    line-height: calc(23.4 / 13);
    font-family: $font-family-proxima;
  }
  &__checkboxItemTxt {
    display: block;
    font-size: 1.3rem;
    line-height: calc(23.4 / 13);
    font-family: $font-family-proxima;
    font-weight: $font-weight-light;
    text-align: center;
  }
  &__checkboxItem {
    display: flex;
    justify-content: center;
    &--attention {
      margin-top: 38px;
    }
    &--verification {
      margin-top: 38px;
    }
  }
  &__checkbox {
    margin-top: 29px;
    @include mq-up(lg) {
      margin-top: 42px;
    }
  }
  &__noUse {
    display: none;
    width: 100%;
    font-size: 1.3rem;
    font-family: $font-family-proxima;
    font-weight: $font-weight-light;
    letter-spacing: .052em;
    line-height: 140%;
    color: #FFEB00;
    position: absolute;
    bottom: -26px;
    left: 0;
    @include mq-up(md) {
      font-size: 1.6rem;
    }
  }
}

.p-modalCart {
  position: relative;
  transition: .2s cubic-bezier(0.82, 0.02, 0.48, 1.13);
  z-index: 2000;
  &.is-hide {
    opacity: 0;
    pointer-events: none;
    visibility: visible;
  }
  &__cont {
    width: 100%;
    max-width: 329px;
    height: 100vh;
    background-color: #D1D1D1;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2000;
    @include mq-up(md) {
      max-width: 360px;
    }
  }
  &__inner {
    padding-right: 29px;
    padding-left: 34px;
    @include mq-up(md) {
      padding: 0 36px;
    }
  }
  &__bg {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-black;
    opacity: 0.62;
    z-index: 1999;
  }
  &__close {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 18px;
    top: 17px;
    cursor: pointer;
    span {
      background-color: $color-black;
      height: 1px;
      left: -3px;
      position: absolute;
      width: 23px;
      display: inline-block;
      &:nth-child(1) {
        top: 0;
        transform: translateY(8px) rotate(-45deg);
      }
      &:nth-child(2) {
        bottom: 0;
        transform: translateY(-7px) rotate(45deg);
      }
    }
  }
  &__null {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &__nullTxt {
    font-family: $font-family-en;
    font-size: 1.7rem;
    font-weight: $font-weight-regular;
    color: $color-black;
    opacity: 0.9;
    line-height: calc(23 / 17);
  }
  &__list {
    max-height: calc(100vh - 562px);
    margin-top: 73px;
    border-bottom: 1px solid #111;
    padding-bottom: 40px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    @include mq-up(md) {
      max-height: calc(100vh - 448px);
    }
  }
  &__item {
    width: 100%;
    display: flex;
    + .p-modalCart__item {
      margin-top: 31px;
      @include mq-up(md) {
        margin-top: 35px;
      }
    }
  }
  &__itemVis {
    width: 92px;
    min-width: 92px;
    height: 92px;
    @include mq-up(md) {
      width: 72px;
      min-width: 72px;
      height: 72px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__itemMeta {
    width: calc(100% - 72px);
    padding-left: 21px;
    @include mq-up(md) {
      padding-left: 13px;
    }
  }
  &__itemName,
  &__itemPrice {
    font-family: $font-family-en;
    font-size: 1.5rem;
    font-weight: $font-weight-regular;
    line-height: calc(23 / 15);
    @include lh-crop(23 / 15);
    opacity: 0.9;
    letter-spacing: 0.02em;
    @include mq-up(md) {
      font-size: 1.6rem;
      line-height: calc(23 / 16);
      @include lh-crop(23 / 16);
    }
  }
  &__itemPrice {
    margin-top: 3px;
    @include mq-up(md) {
      margin-top: 7px;
    }
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 23px;
    @include mq-up(md) {
      margin-top: 26px;
    }
  }
  &__totalLabel {
    font-family: $font-family-en;
    font-size: 1.0rem;
    font-weight: $font-weight-regular;
  }
  &__btn {
    margin-top: 24px;
    .c-button {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      font-family: $font-family-en;
      font-size: 1.3rem;
      font-weight: $font-weight-regular;
      letter-spacing: 0.07em;
      color: rgba($color-black,0.9);
    }
  }
  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: $font-family-en;
    font-size: 1.1rem;
    font-weight: $font-weight-regular;
    line-height: calc(20 / 11);
    @include lh-crop(20 / 11);
    opacity: 0.9;
    color: $color-black;
    padding-right: 29px;
    padding-left: 34px;
    padding-bottom: 160px;
    @include mq-up(md) {
      padding: 0 24px;
      padding-bottom: 24px;
    }
    span {
      display: block;
      margin-bottom: 16px;
    }
  }
  &__null {
    &.is-show {
      transition-delay: .4s;
    }
  }
  &__null,
  &__box {
    &.is-show {
      opacity: 1;
      transition: .3s cubic-bezier(0.82, 0.02, 0.48, 1.13);
    }
    &.is-hide {
      opacity: 0;
      pointer-events: none;
      visibility: visible;
    }
  }
}

// 個数選択
.c-quantity {
  display: flex;
  align-items: center;
}

.c-qtyminus,
.c-qtyplus {
  font-size: 1.4rem;
  cursor: pointer;
}

.c-qty {
  width: 100%;
  max-width: 24px;
  text-align: center;
  font-size: 1.9rem;
  font-family: $font-family-en;
  font-weight: $font-weight-regular;
  line-height: calc(23 / 19);
  opacity: 0.9;
  margin: 0 4px;
}

.js-modalCartBtn {
  cursor: pointer;
}

.p-modalCart__label {
  margin-top: 11px;
  + .p-modalCart__itemQuantity {
    margin-top: 12px;
    @include mq-up(md) {
      margin-top: 8px;
    }
  }
}

.p-modalCart__itemQuantity {
  margin-top: 16px;
  @include mq-up(md) {
    margin-top: 10px;
  }
}