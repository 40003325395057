@use '../../global' as *;
@use 'sass:math';

.p-article {
  & > :first-child {
    margin-top: 0 !important;
  }
  & > :last-child {
    margin-bottom: 0 !important;
  }
  h3 {
    font-family: $font-family-base;
    font-size: 1.4rem;
    font-weight: $font-weight-medium;
    @include lh-crop(20 / 14);
    line-height: calc(20 / 14);
    letter-spacing: 0.03em;
    opacity: 0.99;
    @include mq-up(md) {
      font-size: 1.3rem;
      @include lh-crop(20 / 13);
      line-height: calc(20 / 13);
    }
  }
  h2 {
    font-family: $font-family-base;
    font-size: 1.6rem;
    font-weight: $font-weight-medium;
    @include lh-crop(20 / 16);
    line-height: calc(20 / 16);
    letter-spacing: 0.03em;
    opacity: 0.99;
    @include mq-up(md) {
      font-size: 1.7rem;
      @include lh-crop(20 / 17);
      line-height: calc(20 / 17);
    }
  }
  p {
    font-family: $font-family-base;
    font-size: 1.5rem;
    font-weight: $font-weight-light;
    @include lh-crop(25.5 / 15);
    line-height: calc(25.5 / 15);
    letter-spacing: 0.03em;
    opacity: 0.99;
    @include mq-up(md) {
      font-size: 1.3rem;
      @include lh-crop(20 / 13);
      line-height: calc(20 / 13);
    }
    span {
      font-size: 1.3rem;
      @include lh-crop(25.5 / 13);
      line-height: calc(25.5 / 13);
      display: block;
      margin-top: 14px;
      @include mq-up(md) {
        font-size: 1.1rem;
        @include lh-crop(20 / 11);
        line-height: calc(20 / 11);
        margin-top: 9px;
      }
    }

    a {
      text-decoration: underline;
    }

    small{
      display: block;
      font-size: 1.1rem;
      margin-top: 14px;
        @include mq-up(md) {
          font-size: 1.2rem;
          margin-top: 9px;
        }
    }

    &.p-signature {
      font-family: $font-family-base;
      font-size: 1.5rem;
      font-weight: $font-weight-light;
      @include lh-crop(20 / 15);
      line-height: calc(20 / 15);
      letter-spacing: 0.03em;
      opacity: 0.99;
      margin-top: 43px;
      @include mq-up(md) {
        font-size: 1.3rem;
        @include lh-crop(20 / 13);
        line-height: calc(20 / 13);
        margin-top: 48px;
      }
    }

    span{
      margin-bottom: 2rem;
    }
  }
  ol {
    &.p-headline {
      @include mq-up(md) {
        margin-top: 45px;
      }
      li + li {
        margin-top: 41px;
        @include mq-up(md) {
          margin-top: 26px;
        }
      }
      li ol {
        li + li {
          margin-top: 25px;
          @include mq-up(md) {
            margin-top: 16px;
          }
        }
        .p-row {
          span + p {
            padding-left: 3px;
            @include mq-up(md) {
              padding-left: 8px;
            }
          }
          + p {
            margin-top: 8px;
          }
        }
      }
    }
  }
  ol li {
    font-family: $font-family-base;
    font-size: 1.5rem;
    font-weight: $font-weight-light;
    @include lh-crop(25 / 15);
    line-height: calc(25 / 15);
    letter-spacing: 0.03em;
    opacity: 0.99;
    @include mq-up(md) {
      font-size: 1.3rem;
      @include lh-crop(20 / 13);
      line-height: calc(20 / 13);
    }
    + li {
      margin-top: 20px;
      @include mq-up(md) {
        margin-top: 24px;
      }
    }
    p + ol {
      margin-top: 25px;
      @include mq-up(md) {
        margin-top: 19px;
      }
    }
  }
  * + h3 {
    margin-top: 48px;
    @include mq-up(md) {
      margin-top: 64px;
    }
  }
  h3 + p {
    margin-top: 13px;
    @include mq-up(md) {
      margin-top: 13px;
    }
  }
  h3 + ol {
    margin-top: 13px;
    @include mq-up(md) {
      margin-top: 13px;
    }
  }
  p + ol {
    margin-top: 33px;
    @include mq-up(md) {
      margin-top: 13px;
    }
  }
  p + h2 {
    margin-top: 64px;
    @include mq-up(md) {
      margin-top: 100px;
    }
  }
}

.p-row {
  display: flex;
  align-items: baseline;
  span + p {
    padding-left: 7px;
  }
  span + h4 {
    padding-left: 4px;
  }
  + p {
    margin-top: 15px;
    padding-left: 16px;
    @include mq-up(md) {
      margin-top: 11px;
    }
  }
  + span {
    display: block;
    margin-top: 4px;
    @include mq-up(md) {
      margin-top: 8px;
    }
  }
  span {
    display: block;
    word-break: initial;
  }
  + ul {
    margin-top: 8px;
    li {
      + li {
        margin-top: 4px;
        @include mq-up(md) {
          margin-top: 4px;
        }
      }
    }
    + p {
      margin-top: 8px;
      @include mq-up(md) {
        margin-top: 8px;
      }
    }
  }
}

.p-rowInner{
  margin-left: 2.5rem;
  margin-top: 24px;
}

.p-inline {
  @include mq-up(md) {
    padding-left: 15px;
  }
}

.p-article {
  .p-article__salesSystemImg {
    width: 100%;
    height: 100%;
    margin-top: 13px;
    @include mq-up(md) {
      display: flex;
    }
    &__item {
      @include mq-up(md) {
        width: calc(339px / 782px * 100%);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      + .p-article__salesSystemImg__item {
        margin-top: 10px;
        @include mq-up(md) {
          width: calc(370px / 782px * 100%);
          padding-left: 10px;
          margin-top: 0;
        }
      }
    }
  }
  .p-article__salesList {
    li + li {
      margin-top: 16px;
      @include mq-up(md) {
        margin-top: 12px;
      }
    }
    ul li + li {
      margin-top: 16px;
      @include mq-up(md) {
        margin-top: 12px;
      }
    }
  }
}


