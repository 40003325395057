@use '../../global' as *;
@use 'sass:math';

.c-label {
  background-color: $color-black;
  color: $color-white;
  font-size: 1.2rem;
  font-weight: $font-weight-regular;
  line-height: 100%;
  padding: 3px 9px;
  border-radius: 9px;
  width: auto;
  display: inline-block;
}