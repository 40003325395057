@use '../../global' as *;
@use 'sass:math';

// モード変換
.c-switchBtn {
  position: relative;
  width: 32px;
  height: 16px;
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  @include mq(md) {
    width: 29px;
    height: 15px;
  }
}

.c-switchBtn input {
	display: none;
}

.c-switchBtn input[type='checkbox'] + label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: $color-white;
  transition: 0.2s ease-out;
  border: 1px solid $color-white;
  border-radius: 8px;
  cursor: pointer;
}

.c-switchBtn input[type='checkbox'] + label:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  background: $color-white;
  transition: 0.2s ease-out;
  border: 1px solid #1d1d1d;
  cursor: pointer;
  @include mq(md) {
    width: 15px;
    height: 15px;
  }
}

.c-switchBtn input[type='checkbox']:checked + label:before {
  background: $color-white;
}

.c-switchBtn input[type='checkbox']:checked + label:after {
  right: 16px;
  @include mq(md) {
    right: 14px;
  }
}


.c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  @include mq(md) {
    transition: all .07s cubic-bezier(0.4, 0.25, 0.49, 0.66);
  }
  &:after {
    content: "";
    display: block;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
  }
  &:disabled {
    pointer-events: none;
  }
  &--black {
    background-color: $color-black;
    color: $color-white;
    border: 1px solid $color-black;
    @include hover {
      background-color: transparent;
      color: $color-black;
      border: 1px solid $color-black;
    }
  }
  &--white {
    background-color: #f2f2f2;
    color: $color-black;
    border: 1px solid $color-black;
    @include hover {
      background-color: $color-black;
      color: $color-white;
    }
  }
  &--trans {
    background-color: $pjc-main-01;
    color: $color-black;
    border: 1px solid $color-black;
  }
  &--disabled {
    background-color: #ADADAD;
    color: #E5E5E5;
    pointer-events: none;
  }
  &--arrowDiagonal {
    &:after {
      background-image: img-url("icon-arrow-diagonal_black.svg");
      top: 10px;
      right: 8px;
      background-size: contain;
      width: 8px;
      height: 8px;
      @include mq(md) {
        background-size: auto;
        right: 10px;
        width: 1em;
        height: 1em;
      }
    }
    @include hover {
      &:after {
        background-image: img-url("icon-arrow-diagonal_white.svg");
      }
    }
  }
  &--arrow {
    &:after {
      background-image: img-url("icon-arrow_black.svg");
      right: 8px;
      transform: translate(0,-50%);
      top: calc(50% + 1px);
    }
    @include hover {
      &:after {
        background-image: img-url("icon-arrow_white.svg");
      }
    }
  }
  &--middle{
    height: 48px;
  }
}


.c-button + .c-button {
  margin-top: 8px;
}