@use '../../global' as *;
@use 'sass:math';

.p-portal {
  width: 100%;
  padding: 30px 0 123px;
  @include mq-up(md) {
    max-width: 1049px;
    margin: 0 auto;
  }
  ul {
    @include mq-up(md) {
      display: flex;
      justify-content: space-between;
    }
  }
  ul li {
    width: 100%;
    height: 57px;
    border: 1px solid $color-black;
    background-color: $color-white;
    border-radius: 5px;
    font-size: 1.8rem;
    font-weight: $font-weight-light;
    color: $color-black;
    font-family: $font-family-base;
    @include mq-up(md) {
      width: calc(512px / 1049px * 100%);
      // margin: 0 12px;
    }
    cursor: pointer;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s cubic-bezier(0.25, 1, 0.5, 1);
      @include hover {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }
  p + ul {
    margin-top: 67px;
    @include mq-up(md) {
      margin-top: 107px;
    }
  }
  ul + ul {
    margin-top: 24px;
    &.p-portalPtb {
      @include mq-up(md) {
        margin-top: 0;
      }
      + ul {
        @include mq-up(md) {
          margin-top: 0;
        }
      }
    }
  }
  li + li {
    margin-top: 7px;
    @include mq-up(md) {
      margin-top: 0;
    }
  }
  p {
    text-align: right;
    margin-top: 12px;
    font-size: 1.8rem;
    font-weight: $font-weight-regular;
    color: $color-black;
    font-family: $font-family-base;
    letter-spacing: 0.03em;
    @include lh-crop(26 / 18);
    line-height: calc(26 / 18);
    padding-right: 16px;
  }
  ul li.p-portal__disabled {
    // cursor: inherit;
    background-color: transparent;
    opacity: 0.6;
    a {
      pointer-events: none;
    }
  }
}

.p-portalPtb {
  padding-top: 44px;
  padding-bottom: 84px;
  @include mq-up(md) {
    padding: 80px 0;
    margin-top: 0;
  }
}

.p-portalPt {
    padding-top: 44px;
  @include mq-up(md) {
    padding: 44px 0 0 0;
    margin-top: 0;
  }
}
