@use '../../global' as *;
@use 'sass:math';

.c-tax {
  display: flex;
  align-items: center;
  &__txt {
    font-size: 1.7rem;
    font-family: $font-family-en;
    @include lh-crop(29 / 17);
    line-height: calc(29 / 17);
    margin-top: 0;
    font-weight: $font-weight-regular;
    @include mq-up(md) {
      margin-top: 18px;
      font-size: 1.9rem;
      @include lh-crop(29 / 19);
      line-height: calc(29 / 19);
      margin-top: 0;
    }
  }
  &__inc {
    font-size: 1.4rem;
    font-family: $font-family-en;
    font-weight: $font-weight-regular;
    @include lh-crop(29 / 14);
    line-height: calc(29 / 14);
    margin-left: 10px;
    @include mq-up(md) {
      font-size: 1.5rem;
      @include lh-crop(29 / 15);
      line-height: calc(29 / 15);
      margin-left: 7px;
    }
    span {
      display: inline-block;
      line-height: 100%;
      padding-left: 0;
      @include mq-up(md) {
        padding-left: 0;
      }
    }
  }
}

.p-modalCart__itemPrice {
  .c-tax {
    display: block;
    @include mq-up(md) {
      display: flex;
    }
    &__txt {
      font-size: 1.5rem;
      @include lh-crop(29 / 15);
      line-height: calc(29 / 15);
      @include mq-up(md) {
        font-size: 1.4rem;
        @include lh-crop(29 / 14);
        line-height: calc(29 / 14);
      }
    }
    &__inc {
      font-size: 1.2rem;
      @include lh-crop(29 / 12);
      line-height: calc(29 / 12);
      margin-left: 0px;
      @include mq-up(md) {
        font-size: 1.1rem;
        @include lh-crop(29 / 11);
        line-height: calc(29 / 11);
        margin-left: 5px;
      }
      span {
        display: inline-block;
        line-height: 100%;
        padding-left: 0;
        @include mq-up(md) {
          padding-left: 0;
        }
      }
    }
  }
}

.p-modalCart__totalPrice {
  .c-tax {
    &__txt {
      font-size: 1.6rem;
      @include lh-crop(29 / 16);
      line-height: calc(29 / 16);
      @include mq-up(md) {
        font-size: 1.6rem;
        @include lh-crop(29 / 16);
        line-height: calc(29 / 16);
      }
    }
    &__inc {
      font-size: 1.3rem;
      @include lh-crop(29 / 13);
      line-height: calc(29 / 13);
      margin-left: 4px;
      @include mq-up(md) {
        margin-left: 4px;
        font-size: 1.3rem;
        @include lh-crop(29 / 13);
        line-height: calc(29 / 13);
      }
    }
  }
}

.p-mypage__historyItemPay {
  .c-tax {
    margin-top: 5px;
    @include mq-up(md) {
      margin-top: 0;
    }
    &__txt {
      font-size: 1.6rem;
      @include lh-crop(29 / 16);
      line-height: calc(29 / 16);
      @include mq-up(md) {
        font-size: 1.6rem;
        @include lh-crop(29 / 16);
        line-height: calc(29 / 16);
      }
    }
    &__inc {
      font-size: 1.2rem;
      @include lh-crop(29 / 12);
      line-height: calc(29 / 12);
      margin-left: 7px;
      @include mq-up(md) {
        margin-left: 7px;
        font-size: 1.2rem;
        @include lh-crop(29 / 12);
        line-height: calc(29 / 12);
      }
    }
  }
}

.p-productMain__specProductTax {
  .c-tax {
    &__txt {
      font-size: 1.9rem;
      @include lh-crop(29 / 19);
      line-height: calc(29 / 19);
      letter-spacing: 0.02em;
      white-space: nowrap;
      @include mq-up(md) {
        font-size: 1.9rem;
        @include lh-crop(29 / 19);
        line-height: calc(29 / 19);
      }
    }
    &__inc {
      font-size: 1.6rem;
      @include lh-crop(29 / 16);
      line-height: calc(29 / 16);
      margin-left: 7px;
      letter-spacing: 0.04em;
      white-space: nowrap;
      @include mq-up(md) {
        margin-left: 12px;
        font-size: 1.5rem;
        @include lh-crop(29 / 15);
        line-height: calc(29 / 15);
      }
    }
  }
}