@use '../../global' as *;
@use 'sass:math';

.p-footer {
  display: flex;
  align-items: center;
  padding-top: 54px;
  padding-bottom: 26px;
  @include mq-up(md) {
    padding-top: 56px;
    padding-bottom: 32px;
  }
  &__foot {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 45px;
    @include mq-up(md) {
      margin-top: 165px;
    }
  }
  &__logo {
    width: 100%;
    height: 100%;
    @include mq-up(md) {
      width: calc(100% / 5 - 16px);
      margin-left: -2px;
      margin-top: -8px;
      img {
        width: 236px;
        min-width: 236px;
      }
    }
  }
  &__head {
    display: flex;
    flex-direction: column;
    @include mq-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__nav {
    display: flex;
    flex-wrap: wrap;
    margin-top: 29px;
    width: 340px;
    margin-top: 46px;
    @include mq-up(md) {
      width: calc(782px / 1318px * 100%);
      justify-content: space-between;
      margin-top: 0;
    }
  }
  &__navItem {
    font-family: $font-family-en;
    font-weight: $font-weight-light;
    font-size: 1.4rem;
    line-height: calc(18 / 16);
    letter-spacing: 0.01em;
    color: $color-white;
    width: 100%;
    margin-top: 12px;
    @include mq-up(md) {
      width: calc(100% / 2);
      font-size: 1.5rem;
      letter-spacing: 0.02em;
      font-weight: $font-weight-medium;
      line-height: calc(17 / 15);
      margin-top: 13px;
      padding-right: 24px;
    }
    @include mq-up(lg) {
      width: calc(100% / 3);
    }
    &:first-child {
      margin-top: 0;
      @include mq-up(md) {
        margin-top: 13px;
      }
    }
    &--spHidden {
      display: none;
      @include mq-up(md) {
        display: block;
      }
    }
    a {
      position: relative;
      line-height: 160%;
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        background-color: $color-white;
        bottom: -3px;
        left: 0;
        right: 0;
        opacity: 0;
        transition: .15s cubic-bezier(.49,.09,.38,.81);
      }
      @include hover {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  &__copyright {
    @include lh-crop(12 / 11);
    line-height: calc(12 / 11);
    font-size: 1.1rem;
    font-family: $font-family-roboto;
    font-weight: $font-weight-light;
    color: $color-white;
    @include mq-up(md) {
      font-size: 1.3rem;
      @include lh-crop(18 / 13);
      line-height: calc(18 / 13);
      margin-bottom: 3px;
    }
  }
  &__shareWrap {
    display: flex;
  }
  &__shareIcon {
    transform: scale(1.1);
    @include mq-up(md) {
      transform: scale(1);
    }
    + .p-footer__shareIcon {
      margin-left: 21px;
      @include mq-up(md) {
        margin-left: 24px;
      }
    }
  }
}
