@use '../../global' as *;
@use 'sass:math';

.p-front {
  width: 100%;
  height: 100%;
  position: relative;
  &__logo {
    position: absolute;
    top: 5px;
    left: 19px;
    @include mq-up(md) {
      top: 20px;
      left: 24px;
    }
  }
  &__container {
    z-index: -1;
  }
  &__slide {
    width: 100%;
    height: 667px;
    @include mq-up(md) {
      height: 1080px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__progress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    position: absolute;
    right: 17.5px;
    &.is-none {
      display: none;
    }
  }
  &__progressBar {
    position: relative;
    width: 28px;
    height: 1px;
    background-color: $color-white;
    overflow: hidden;
  }
  &__progressBarItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: $pjc-accent-01;
    z-index: 1;
  }
  &__vis {
    position: relative;
    @include mq-up(md) {
      width: calc(100% - 289px);
    }
  }
  &__visInfo {
    width: 100%;
    height: 667px;
    position: absolute;
    top: 0;
    left: 0;
    @include mq-up(md) {
      width: calc(100% - 289px);
      height: 100vh;
    }
  }
  &__visInfoBox {
    &[data-current="1"] {
      .p-front__visInfoTxt:nth-child(1) {
        opacity: 1;
      }
    }
    &[data-current="2"] {
      .p-front__visInfoTxt:nth-child(2) {
        opacity: 1;
      }
    }
    &[data-current="3"] {
      .p-front__visInfoTxt:nth-child(3) {
        opacity: 1;
      }
    }
    &[data-current="4"] {
      .p-front__visInfoTxt:nth-child(4) {
        opacity: 1;
      }
    }
    &[data-current="5"] {
      .p-front__visInfoTxt:nth-child(5) {
        opacity: 1;
      }
    }
    &[data-current="6"] {
      .p-front__visInfoTxt:nth-child(6) {
        opacity: 1;
      }
    }
    &[data-current="7"] {
      .p-front__visInfoTxt:nth-child(7) {
        opacity: 1;
      }
    }
    &[data-current="8"] {
      .p-front__visInfoTxt:nth-child(8) {
        opacity: 1;
      }
    }
    &[data-current="9"] {
      .p-front__visInfoTxt:nth-child(9) {
        opacity: 1;
      }
    }
    &[data-current="10"] {
      .p-front__visInfoTxt:nth-child(10) {
        opacity: 1;
      }
    }
  }
  &__visInfoTxt {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 17.5px;
    @include mq-up(md) {
      position: relative;
      left: 0;
    }
    // &.is-active {
    //   opacity: 1;
    // }
  }
  &__visInfoTxtBox {
    mix-blend-mode: exclusion;
    @include mq-up(md) {
      position: fixed;
      bottom: 54px;
      left: 24px;
    }
  }
  &__visInfoTxtBtn {
    @include mq-up(md) {
      position: fixed;
      bottom: 28px;
      left: 24px;
    }
  }
  &__visInfoInner {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 40px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 17.5px;
    @include mq-up(md) {
      // justify-content: flex-end;
      padding: 0 24px;
      height: 100vh;
      bottom: 28px;
    }
  }
  &__visName {
    font-size: 1.6rem;
    @include lh-crop(20 / 16);
    line-height: calc(20 / 16);
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    color: $color-white;
    display: none;
    // opacity: 0.9;
    margin-bottom: 28px;
    @include mq-up(md) {
      display: block;
    }
  }
  &__visMetaTxt {
    font-size: 1.5rem;
    @include lh-crop(22 / 15);
    line-height: calc(22 / 15);
    font-family: $font-family-en;
    font-weight: $font-weight-regular;
    color: $color-white;
    letter-spacing: 0.03em;
    opacity: 0.9;
    margin-top: 2px;
    @include mq-up(md) {
      font-size: 1.6rem;
      @include lh-crop(20 / 16);
      line-height: calc(20 / 16);
      font-weight: $font-weight-medium;
      letter-spacing: 0;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 30px;
      @include mq-up(md) {
        margin-bottom: 49px;
      }
    }
  }
  &__visBtn {
    width: 244px;
    height: 26px;
    background-color: $pjc-main-01;
    color: $color-black;
    border-radius: 13px;
    font-size: 1.4rem;
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    padding-top: 1px;
    padding-left: 3px;
    border: 1px solid $color-black;
    @include hover {
      background-color: $color-black;
      color: $color-white;
    }
  }
  &__menu {
    width: 100%;
    height: 100%;
    @include mq-up(md) {
      width: 289px;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 22px;
      padding-top: 140px;
      background-color: $pjc-main-01;
    }
  }
  &__menuBoxInner {
    margin-left: 18px;
    @include mq-up(md) {
      margin-left: 0;
    }
  }
  &__menuBoxInner {
    position: initial;
  }
  &__menuList {
    display: block;
    left: 17.5px;
    position: absolute;
    top: 82px;
    height: auto;
    width: auto;
    box-sizing: border-box;
    mix-blend-mode: exclusion;
    z-index: -1;
    @include mq-up(md) {
      mix-blend-mode: normal;
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  &__menuItem {
    font-size: 1.3rem;
    line-height: calc(20 / 13);
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    letter-spacing: 0;
    opacity: 0.4;
    position: relative;
    padding-left: 18px;
    height: auto;
    color: $color-white;
    transition: .2s cubic-bezier(0.49, 0.09, 0.38, 0.81);
    cursor: pointer;
    @include mq-up(md) {
      letter-spacing: 0.016em;
      line-height: calc(16 / 13);
      padding-left: 16px;
      opacity: 0.4;
      color: $color-black;
    }
    @include hover {
      text-decoration: underline;
      opacity: 1;
    }
    &:before {
      content: "";
      display: block;
      width: 11.42px;
      height: 7.22px;
      background-image: img-url("icon-dualArrow_white.svg");
      background-repeat: no-repeat;
      background-size: auto;
      position: absolute;
      left: 0;
      top: 8px;
      @include mq-up(md) {
        top: 6px;
        background-image: img-url("icon-dualArrow_black.svg");
      }
    }
    &.is-opacity {
      pointer-events: none;
      opacity: 1;
      @include mq-up(md) {
        opacity: 1;
      }
      &:before {
        @include mq-up(md) {
          top: 6px;
          opacity: 1;
        }
      }
    }
    &.swiper-slide-thumb-active {
      opacity: 0.9;
      @include mq-up(md) {
        text-decoration: underline;
      }
      &:before {
        opacity: 1;
      }
    }
    + .p-front__menuItem {
      margin-top: 7px;
      @include mq-up(md) {
        margin-top: 11px;
      }
    }
  }
  &__menuDtl {
    padding: 0 19px;
    margin-top: 40px;
    margin-bottom: 50px;
    @include mq-up(md) {
      padding: 0;
      margin-top: 56px;
      margin-bottom: 0;
    }
  }
  &__menuDtlEn {
    font-size: 1.8rem;
    @include lh-crop(25 / 18);
    line-height: calc(25 / 18);
    font-family: $font-family-en;
    font-weight: $font-weight-regular;
    opacity: 0.9;
    @include mq-up(md) {
      font-size: 1.3rem;
      @include lh-crop(17 / 13);
      line-height: calc(17 / 13);
      letter-spacing: 0.02em;
      font-weight: $font-weight-medium;
    }
  }
  &__menuDtlJp {
    font-size: 1.4rem;
    @include lh-crop(27 / 14);
    line-height: calc(27 / 14);
    font-family: $font-family-base;
    font-weight: $font-weight-light;
    opacity: 0.99;
    margin-top: 27px;
    @include mq-up(md) {
      font-size: 1.2rem;
      @include lh-crop(24 / 12);
      line-height: calc(24 / 12);
      margin-top: 16px;
    }
  }
  &__nextCont {
    display: none;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
    @include mq-up(md) {
      display: block;
    }
    &Icon {
      width: 11.05px;
      height: 8.5px;
      background-image: url(../images/icon-next_black.svg);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 24px;
      right: 24px;
      z-index: 1;
    }
  }
}

.p-frontProduct + .p-frontProductList {
  margin-top: 74px;
  margin-bottom: 62px;
}

.p-frontProduct {
  margin-bottom: 74px;
  &__vis {
    width: 100%;
    img {
      width: 100%;
      // height: 617px;
      // object-fit: cover;
      // @include mq-up(md) {
      //   height: 768px;
      // }
    }
  }
  &__spec {
    padding: 0 19.5px;
    margin-top: 42px;
    @include mq(lg) {
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
    }
  }
  &__specTtl {
    font-size: 2.0rem;
    letter-spacing: 0.03em;
    @include lh-crop(22 / 20);
    line-height: calc(22 / 20);
    font-family: $font-family-en;
    font-weight: $font-weight-regular;
    opacity: 0.99;
    @include mq-up(md) {
      font-size: 2.7rem;
      @include lh-crop(31 / 27);
      line-height: calc(31 / 27);
    }
  }
  &__specDtl {
    font-size: 1.4rem;
    font-family: $font-family-base;
    font-weight: $font-weight-light;
    @include lh-crop(24 / 14);
    line-height: calc(24 / 14);
    opacity: 0.99;
    margin-top: 19px;
  }
  &__specMain {
    width: 100%;
    @include mq(lg) {
      width: calc(513px / 1318px * 100%);
    }
  }
  &__specSub {
    margin-top: 22px;
    width: 100%;
    @include mq(lg) {
      margin-top: 0;
      width: calc(284px / 1318px * 100%);
    }
  }
  &__specMeta {
    font-size: 1.4rem;
    font-family: $font-family-en;
    font-weight: $font-weight-regular;
    @include lh-crop(19 / 14);
    line-height: calc(19 / 14);
    letter-spacing: 0.016em;
    opacity: 0.9;
    @include mq-up(md) {
      font-size: 1.3rem;
      @include lh-crop(20 / 13);
      line-height: calc(20 / 13);
    }
  }
  &__specTax {
    margin-top: 15px;
    @include mq-up(md) {
      margin-top: 18px;
    }
  }
  &__specBtn {
    width: 180px;
    height: 30px;
    border-radius: 15px;
    margin-top: 22px;
    padding-top: 2px;
    padding-right: 4px;
    font-size: 1.3rem;
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    letter-spacing: 0.07em;
    @include mq(lg) {
      width: 100%;
    }
    @include hover {
      background-color: $color-black;
      color: $color-white;
    }
  }
}

.p-frontProductList {
  padding: 0 6px;
  @include mq-up(md) {
    padding: 0 24px;
  }
  &__wrap {
    @include mq-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @include mq-up(md) {
      margin-top: -40px;
    }
    @include mq-up(lg) {
      margin-top: -11px;
    }
  }
  &__item {
    position: relative;
    @include mq-up(md) {
      margin-top: 40px;
    }
    @include mq-up(lg) {
      margin-top: 11px;
    }
    + .p-frontProductList__item {
      margin-top: 40px;
      @include mq-up(md) {
        margin-top: 40px;
      }
      @include mq-up(lg) {
        margin-top: 11px;
      }
    }
    @include mq-up(md) {
      width: calc(654px / 1318px * 100%);
    }
    a {
      @include hover{
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  &__info {
    &--colorWhite {
      .p-frontProductList__ttl,
      .p-frontProductList__spec {
        color: $color-white;
      }
      .p-frontProductList__specMeta,
      .p-frontProductList__specTax {
        color: $color-black;
        @include mq-up(lg) {
          color: $color-white;
        }
      }
    }
  }
  &__ttl {
    font-size: 2.0rem;
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    letter-spacing: 0.03em;
    @include lh-crop(23 / 20);
    line-height: calc(23 / 20);
    position: absolute;
    top: calc(30px / 363px * 100%);
    left: calc(14px / 363px * 100%);
    color: $color-black;
    @include mq-up(md) {
      font-size: 2.8rem;
      @include lh-crop(32 / 28);
      line-height: calc(32 / 28);
      top: calc(23px / 654px * 100%);
      left: calc(16px / 654px * 100%);
    }
    + .p-frontProductList__btn {
      margin-left: 11.5px;
      margin-top: 13px;
      @include mq(lg) {
        position: absolute;
        bottom: calc(39px / 654px * 100%);
        right: 16px;
      }
    }
  }
  &__spec {
    padding: 17px 11.5px 0 11.5px;
    color: $color-black;
    @include mq(lg) {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      padding: 0;
      position: absolute;
      width: 100%;
      bottom: calc(39px / 654px * 100%);
      left: 0;
      right: 0;
      padding: 0 16px;
    }
  }
  &__specTxt {
    @include mq-up(lg) {
      margin-bottom: 4px;
    }
  }
  &__specMeta {
    font-size: 1.3rem;
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    @include lh-crop(20 / 13);
    line-height: calc(20 / 13);
    opacity: 0.9;
    @include mq-up(md) {
      font-size: 1.7rem;
      @include lh-crop(23 / 17);
      line-height: calc(23 / 17);
    }
  }
  &__specTax {
    margin-top: 15px;
    @include mq-up(md) {
      margin-top: 16px;
    }
  }
  &__btn {
    width: 180px;
    height: 30px;
    border-radius: 15px;
    margin-top: 19px;
    padding-top: 2px;
    padding-right: 4px;
    font-size: 1.3rem;
    font-family: $font-family-en;
    font-weight: $font-weight-medium;
    letter-spacing: 0.07em;
    @include mq-up(lg) {
      width: 253px;
      margin-top: 0;
    }
    @include hover {
      background-color: $color-black;
      color: $color-white;
    }
  }
}